import { inject, Injectable } from '@angular/core';
import { ChangePinDialogComponent } from '@merchant-portal/app/core/pin/change-pin/change-pin-dialog/change-pin-dialog.component';
import { CheckPinDialogComponent } from '@merchant-portal/app/core/pin/check-pin/check-pin-dialog/check-pin-dialog.component';
import { ForgotPinDialogComponent } from '@merchant-portal/app/core/pin/forget-pin/forgot-pin-dialog/forgot-pin-dialog.component';
import { DisbursementHistoryDetailComponent } from '@merchant-portal/app/features/dashboard/pages/disbursement/disbursement-history/all-disbursement-history/disbursement-history-detail/disbursement-history-detail.component';
import { WithdrawalHistoryDetailComponent } from '@merchant-portal/app/features/dashboard/pages/payment/withdrawal-history/withdrawal-history-detail/withdrawal-history-detail.component';
import { WithdrawalRequestDialogComponent } from '@merchant-portal/app/features/dashboard/pages/payment/withrawal-request-dialog/withdrawal-request.component-dialog';
import { CreateEditTeamMemberComponent } from '@merchant-portal/app/features/dashboard/pages/setting/team-member/create-edit-team-member/create-edit-team-member.component';
import { TransactionHistoryDetailComponent } from '@merchant-portal/app/features/dashboard/pages/transaction-history/transaction-history-detail/transaction-history-detail.component';
import { BulkTransactionItemConfirmation } from '@merchant-portal/app/models/disbursement/bulk-transaction/bulk-transaction.interface';
import { SingleTransactionData } from '@merchant-portal/app/models/disbursement/single-transaction/single-transaction.interface';
import {
  IWithdrawalPrepareResponse,
  WithdrawalConfirmation,
} from '@merchant-portal/app/models/payment/payment.interface';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BulkTransactionConfirmationDialogComponent } from './bulk-transaction-confirmation-dialog/bulk-transaction-confirmation-dialog.component';
import { BulkTransactionSummaryDialogComponent } from './bulk-transaction-summary-dialog/bulk-transaction-summary-dialog.component';
import { ChangePasswordDialogComponent } from './change-password-dialog/change-password-dialog.component';
import { CheckPasswordDialogComponent } from './check-password-dialog/check-password-dialog.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { HelpCenterDialogComponent } from './help-center-dialog/help-center-dialog.component';
import { InvitationDialogComponent } from './invitation-dialog/invitation-dialog.component';
import { ShowRolePermissionDialogComponent } from './show-role-permission-dialog/show-role-permission-dialog.component';
import {
  ISinggleFormDialogData,
  SinggleFormDialogComponent,
} from './singgle-form-dialog/singgle-form-dialog.component';
import { SuccessDialogComponent } from './success-dialog/success-dialog.component';
import { TopUpDialogComponent } from './top-up-dialog/top-up-dialog.component';
import { TransactionConfirmationDialogComponent } from './transaction-confirmation-dialog/transaction-confirmation-dialog.component';
import { TransactionCreatedDialogComponent } from './transaction-created-dialog/transaction-created-dialog.component';
import { TransactionRejectDialogComponent } from './transaction-reject-dialog/transaction-reject-dialog/transaction-reject-dialog.component';
import { UploadCompleteDialogComponent } from './upload-transaction-complete-dialog/upload-transaction-complete-dialog.component';
import { WithdrawalConfirmationDialogComponent } from './withdrawal-confirmation-dialog/withdrawal-confirmation-dialog.component';

export interface DialogBody {
  width: string;
  height: string;
  body: {
    title: string;
    message: string;
    closeText: string | undefined;
    confirmText: string | undefined;
    isDoNothing?: boolean;
  };
  closable: boolean;
}

interface IBaseDailog<T> {
  width: string;
  height: string;
  body: T;
  closable?: boolean;
}

interface IRolePermisisonDialog {
  roleName: string;
  isDefaultRole: boolean;
  uuid: string;
}

export interface IInvitationDialogBody {
  title: string;
  message: string;
  confirmText?: string;
  closeText?: string;
  formData?: {
    memberName?: string;
    email?: string;
    role?: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class DialogComponentService {
  ref: DynamicDialogRef | undefined;
  private dialogService: DialogService = inject(DialogService);

  openHelpCenterDialog(obj: any) {
    const { width, height, body } = obj;
    this.ref = this.dialogService.open(HelpCenterDialogComponent, {
      width: width,
      height: height,
      closable: false,
      data: body,
    });
  }

  openTopUpDialog(obj: any) {
    const { width, height, body } = obj;
    this.ref = this.dialogService.open(TopUpDialogComponent, {
      width: width,
      height: height,
      closable: true,
      styleClass: 'headerDialog',
      data: body,
    });

    return this.ref;
  }

  openSuccessDialog(
    obj: IBaseDailog<{
      title?: string;
      message?: string;
      closeText?: string;
      closeValue?: boolean;
    }>,
  ) {
    const { width, height, body, closable } = obj;
    const dialog = this.dialogService.open(SuccessDialogComponent, {
      width: width,
      height: height,
      closable: closable ?? false,
      data: body,
    });
    this.ref = dialog;

    return dialog;
  }

  openTransactionConfirmationDialog(obj: {
    title?: string;
    message?: string;
    width?: string;
    height?: string;
    body?: SingleTransactionData;
    bulkContent?: BulkTransactionItemConfirmation;
  }) {
    const { width, height, body, title, message, bulkContent } = obj;
    const data = this.dialogService.open(
      TransactionConfirmationDialogComponent,
      {
        width: width,
        height: height,
        closable: true,
        data: {
          content: body,
          title,
          message,
          bulkContent: bulkContent,
        },
      },
    );
    this.ref = data;
    return data;
  }

  openTransactionRejectDialog(obj: {
    title: string;
    message: string;
    width: string;
    height: string;
    isSpecify?: boolean;
  }) {
    const { width, height, title, message, isSpecify } = obj;
    const dialog = this.dialogService.open(TransactionRejectDialogComponent, {
      width: width,
      height: height,
      closable: true,
      styleClass: 'rejectDialog',
      data: { title, message, isSpecify },
    });
    this.ref = dialog;
    return dialog;
  }

  openErrorDialog(obj: any) {
    const { width, height, body } = obj;
    const dialog = this.dialogService.open(ErrorDialogComponent, {
      width: width,
      height: height,
      closable: true,
      data: body,
    });
    this.ref = dialog;
    return dialog;
  }

  openBulkTransactionConfirmationDialog(obj: any) {
    const { width, height, body } = obj;
    const dialog = this.dialogService.open(
      BulkTransactionConfirmationDialogComponent,
      {
        width: width,
        height: height,
        closable: true,
        data: body,
      },
    );
    this.ref = dialog;
    return dialog;
  }

  openTransactionCreatedDialog(obj: any) {
    const { width, height, body } = obj;
    const dialog = this.dialogService.open(TransactionCreatedDialogComponent, {
      width: width,
      height: height,
      closable: false,
      data: body,
    });

    this.ref = dialog;

    return dialog;
  }

  openCreateEditTeamMemberDialog(obj: any) {
    const { width, height, body } = obj;
    const dialog = this.dialogService.open(CreateEditTeamMemberComponent, {
      width: width,
      height: height,
      closable: false,
      data: body,
    });
    this.ref = dialog;
    return dialog;
  }

  openConfirmationDialog(obj: DialogBody) {
    const { width, height, body, closable } = obj;
    const dialog = this.dialogService.open(ConfirmationDialogComponent, {
      width: width,
      height: height,
      closable: closable,
      data: body,
    });
    this.ref = dialog;
    return this.ref;
  }

  openChangePinDialog(obj: { width: string; height: string }) {
    const { width, height } = obj;
    const dialog = this.dialogService.open(ChangePinDialogComponent, {
      width: width,
      height: height,
      closable: true,
    });

    this.ref = dialog;
    return this.ref;
  }

  openPinValidationDialog(obj: {
    width: string;
    height: string;
    data?: {
      isWithoutChecking?: boolean;
      error?: string;
    };
  }) {
    const { data } = obj;
    const dialog = this.dialogService.open(CheckPinDialogComponent, {
      height: 'fit-content',
      width: '500px',
      closable: true,
      data: data,
    });
    this.ref = dialog;
    return this.ref;
  }
  openBulkTransactionSummaryDialog(obj: any) {
    const { width, height, body } = obj;
    const dialog = this.dialogService.open(
      BulkTransactionSummaryDialogComponent,
      {
        width: width,
        height: height,
        closable: true,
        data: body,
      },
    );
    this.ref = dialog;
    return dialog;
  }

  openChangePasswordDialog(data: { currentPassword: string }) {
    const dialog = this.dialogService.open(ChangePasswordDialogComponent, {
      width: '1000px',
      height: '700px',
      closable: true,
      data,
    });
    this.ref = dialog;
    return dialog;
  }

  openCheckPasswordDialog() {
    const dialog = this.dialogService.open(CheckPasswordDialogComponent, {
      width: '1000px',
      height: '700px',
      closable: true,
    });
    this.ref = dialog;
    return dialog;
  }

  openShowRolePermissionDialog(obj: IBaseDailog<IRolePermisisonDialog>) {
    const { width, height, body } = obj;
    const dialog = this.dialogService.open(ShowRolePermissionDialogComponent, {
      width: width,
      height: height,
      closable: true,
      data: body,
    });
    this.ref = dialog;
    return dialog;
  }
  openDisbursementHistoryDetailDialog(id: string, title: string) {
    const dialog = this.dialogService.open(DisbursementHistoryDetailComponent, {
      width: '500px',
      closable: true,
      header: title,
      data: { id },
      styleClass: 'disbursementHistoryDetailDialog',
    });
    this.ref = dialog;
    return dialog;
  }
  openUploadCompleteDialog(obj: DialogBody) {
    const { width, height, body, closable } = obj;
    const dialog = this.dialogService.open(UploadCompleteDialogComponent, {
      width: width,
      height: height,
      closable: closable,
      data: body,
      styleClass: 'headerDialog',
    });
    this.ref = dialog;
    return this.ref;
  }

  openSinggleFormDialog(obj: ISinggleFormDialogData) {
    const { title, subtitle, buttonText, formLabel, store } = obj;
    const dialog = this.dialogService.open(SinggleFormDialogComponent, {
      width: '1000px',
      height: '700px',
      closable: true,
      data: { title, subtitle, buttonText, formLabel, store },
    });
    this.ref = dialog;
    return this.ref;
  }

  openResetPinDialog() {
    const dialog = this.dialogService.open(ForgotPinDialogComponent, {
      width: '1000px',
      height: '700px',
      closable: true,
    });
    this.ref = dialog;
    return this.ref;
  }

  openTransactionHistoryDetailDialog(id: string, title: string) {
    const dialog = this.dialogService.open(TransactionHistoryDetailComponent, {
      width: '500px',
      closable: true,
      header: title,
      data: { id },
      styleClass: 'transactionHistoryDetailDialog',
    });
    this.ref = dialog;
    return dialog;
  }

  openInvitationUserDialog(obj: {
    width: string;
    height: string;
    closable: boolean;
    body?: {
      formOrder?: string[];
      fromData?: any;
      closeText?: string;
      confirmText?: string;
      title?: string;
      message?: string;
    };
  }) {
    const { width, height, closable, body } = obj;
    const dialog = this.dialogService.open(InvitationDialogComponent, {
      width: width,
      height: height,
      closable: closable,
      data: body,
    });
    this.ref = dialog;
    return dialog;
  }

  openWithdrawalHistoryDetailDialog(id: string, title: string) {
    const dialog = this.dialogService.open(WithdrawalHistoryDetailComponent, {
      width: '500px',
      closable: true,
      header: title,
      data: { id },
      styleClass: 'withdrawalHistoryDetailDialog',
    });
    this.ref = dialog;
    return dialog;
  }

  openWithdrawalRequestDialog(data: IWithdrawalPrepareResponse['data']) {
    const dialog = this.dialogService.open(WithdrawalRequestDialogComponent, {
      width: '500px',
      closable: true,
      styleClass: 'withdrawalRequestlDialog',
      data: data,
    });
    this.ref = dialog;
    return dialog;
  }

  openWithdrawalConfirmationDialog(obj: {
    title?: string;
    message?: string;
    width?: string;
    height?: string;
    body?: WithdrawalConfirmation;
  }) {
    const { width, height, body, title, message } = obj;
    const data = this.dialogService.open(
      WithdrawalConfirmationDialogComponent,
      {
        width: width,
        height: height,
        closable: true,
        data: {
          content: body,
          title,
          message,
        },
      },
    );
    this.ref = data;
    return data;
  }
}
