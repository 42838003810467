import { Pipe, type PipeTransform } from '@angular/core';
import {
  DisbursementDetails,
  FeeDetails,
  TransactionHistoryItem,
  WithdrawalDetails,
} from '@merchant-portal/app/models/transaction-history/transaction-history-detail/transaction-history-detail.interface';
import { TRANSACTION_TYPE } from '../../constant/transaction-history.constant';

@Pipe({
  name: 'appTranscationDetail',
  standalone: true,
})
export class TranscationDetailPipe implements PipeTransform {
  transform(value: TransactionHistoryItem | undefined | null) {
    if (!value) {
      return {};
    }
    const feeDetail =
      value.type.includes('FEE') ? (value.details as FeeDetails) : undefined;

    const disbursementDetails =
      value.type === TRANSACTION_TYPE.DISBURSEMENT
        ? (value.details as DisbursementDetails)
        : undefined;

    const withdrawalDetails =
      value.type === TRANSACTION_TYPE.WITHDRAWAL
        ? (value.details as WithdrawalDetails)
        : undefined;

    return {
      feeDetail,
      disbursementDetails,
      withdrawalDetails,
    };
  }
}
