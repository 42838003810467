import { CommonModule, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/id';
import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BlockLoadingComponent } from '@merchant-portal/app/components/loading/block-loading/block-loading.component';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { ToastModule } from 'primeng/toast';
import { Observable } from 'rxjs';
import {
  TRANSACTION_TYPE,
  TRANSACTION_TYPE_LABEL,
} from '../constant/transaction-history.constant';
import { TransactionTypePipe } from '../pipe/transaction-type.pipe';
import {
  TransactionHistoryDetailFactoryService,
  TransactionHistoryDetailState,
} from './factory/transaction-history-detail-store-factory';
import { TranscationDetailPipe } from './pipe/transcation-detail.pipe';
import { TransactionHistoryDetailStore } from './store/transaction-history-detail.store';
registerLocaleData(localeFr, 'id');
@Component({
  selector: 'app-transaction-history-detail',
  templateUrl: './transaction-history-detail.component.html',
  styleUrls: ['./transaction-history-detail.component.scss'],
  standalone: true,
  providers: [
    {
      provide: TransactionHistoryDetailFactoryService,
      useClass: TransactionHistoryDetailStore,
    },
  ],
  imports: [
    CommonModule,
    CardModule,
    InputTextModule,
    ReactiveFormsModule,
    ButtonModule,
    BlockLoadingComponent,
    DialogModule,
    ToastModule,
    BlockLoadingComponent,
    TransactionTypePipe,
    TranscationDetailPipe,
  ],
})
export class TransactionHistoryDetailComponent implements OnInit {
  id = '';
  vm$: Observable<TransactionHistoryDetailState>;
  showTooltip = false;
  typeLabelMap = TRANSACTION_TYPE_LABEL;

  constructor(
    private store: TransactionHistoryDetailFactoryService,
    private dialogConfig: DynamicDialogConfig,
    private ref: DynamicDialogRef,
  ) {
    this.vm$ = this.store.vm$;
    this.id = this.dialogConfig.data?.id;
  }

  ngOnInit() {
    this.store.requestTransactionHistory(this.id);
    this.vm$.subscribe((url) => {
      if (url.downloadUrl) {
        window.open(url.downloadUrl, '_blank');
      }
    });
  }

  isDisbursement(transactionType: string | undefined): boolean {
    return (
      transactionType === TRANSACTION_TYPE.DISBURSEMENT ||
      transactionType === TRANSACTION_TYPE.BULK_DISBURSEMENT
    );
  }

  isWithdrawal(transactionType: string | undefined): boolean {
    return transactionType === TRANSACTION_TYPE.WITHDRAWAL;
  }

  cancel() {
    this.ref.close();
  }
}
